html, body {
  font-family: "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
}
@media (max-width: 419px) {
  html, body {
    font-size: 12px;
  }
}

body {
  color: #000000;

  background-size: 220px 220px;
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Open Sans", sans-serif;
  font-weight: 300;
}

h1 {
  clear: both;
  color: #ff6600;
  font-size: 2em;
  margin: 0 auto;
  width: 99%;
  margin-bottom: 20px;
}

h2 {
  clear: both;
  font-size: 1.8em;
  margin-bottom: 10px;
  padding: 10px 0 10px 30px;
}

h3 {
  border-bottom: 1px solid #c2c2c2;
  padding-left: 5px;
  margin-bottom: 15px;
  margin-top: 30px;
  font-size: 1.4em;
}
h3 > span {
  border-bottom: 2px solid #c2c2c2;
  display: inline-block;
  padding: 0 5px 5px;
}

h4 {
  font-size: 1.2em;
}

h5 {
  font-size: 1em;
}

h6 {
  font-size: 0.875em;
}

a {
  color: #000000;
  outline: none !important;
}
a:hover, a:focus {
  color: #696969;
}

.container {
  padding-left: 8px;
  padding-right: 8px;
}
@media (max-width: 767px) {
  .container {
    padding-left: 5px;
    padding-right: 5px;
  }
}

.row {
  margin-left: -8px;
  margin-right: -8px;
}
@media (max-width: 767px) {
  .row {
    margin-left: -5px;
    margin-right: -5px;
  }
}

.col-xs-1,
.col-sm-1,
.col-md-1,
.col-lg-1,
.col-xs-2,
.col-sm-2,
.col-md-2,
.col-lg-2,
.col-xs-3,
.col-sm-3,
.col-md-3,
.col-lg-3,
.col-xs-4,
.col-sm-4,
.col-md-4,
.col-lg-4,
.col-xs-5,
.col-sm-5,
.col-md-5,
.col-lg-5,
.col-xs-6,
.col-sm-6,
.col-md-6,
.col-lg-6,
.col-xs-7,
.col-sm-7,
.col-md-7,
.col-lg-7,
.col-xs-8,
.col-sm-8,
.col-md-8,
.col-lg-8,
.col-xs-9,
.col-sm-9,
.col-md-9,
.col-lg-9,
.col-xs-10,
.col-sm-10,
.col-md-10,
.col-lg-10,
.col-xs-11,
.col-sm-11,
.col-md-11,
.col-lg-11,
.col-xs-12,
.col-sm-12,
.col-md-12,
.col-lg-12 {
  padding-left: 8px;
  padding-right: 8px;
}
@media (max-width: 767px) {
  .col-xs-1,
  .col-sm-1,
  .col-md-1,
  .col-lg-1,
  .col-xs-2,
  .col-sm-2,
  .col-md-2,
  .col-lg-2,
  .col-xs-3,
  .col-sm-3,
  .col-md-3,
  .col-lg-3,
  .col-xs-4,
  .col-sm-4,
  .col-md-4,
  .col-lg-4,
  .col-xs-5,
  .col-sm-5,
  .col-md-5,
  .col-lg-5,
  .col-xs-6,
  .col-sm-6,
  .col-md-6,
  .col-lg-6,
  .col-xs-7,
  .col-sm-7,
  .col-md-7,
  .col-lg-7,
  .col-xs-8,
  .col-sm-8,
  .col-md-8,
  .col-lg-8,
  .col-xs-9,
  .col-sm-9,
  .col-md-9,
  .col-lg-9,
  .col-xs-10,
  .col-sm-10,
  .col-md-10,
  .col-lg-10,
  .col-xs-11,
  .col-sm-11,
  .col-md-11,
  .col-lg-11,
  .col-xs-12,
  .col-sm-12,
  .col-md-12,
  .col-lg-12 {
    padding-left: 5px;
    padding-right: 5px;
  }
}

/* new xxs layout for extra small devices */
@media (max-width: 419px) {
  h1 {
    padding-left: 5px;
    font-size: 1.8em;
  }

  h2 {
    font-size: 1.5em;
  }

  .hidden-xxs {
    display: none !important;
  }

  tr.hidden-xxs {
    display: table-row !important;
  }

  th.hidden-xxs,
  td.hidden-xxs {
    display: table-cell !important;
  }

  .visible-xxs {
    display: block !important;
  }

  tr.visible-xxs {
    display: none !important;
  }

  th.visible-xxs,
  td.visible-xxs {
    display: none !important;
  }

  .breadcrumb {
    padding-left: 6px;
  }
}
#theme-wrapper {
  box-shadow: 0 0 53px 0 rgba(0, 0, 0, 0.55);
  max-width: 1920px;
}

#page-wrapper {
  background-color: #E8EBF2;
}

.container {
  margin: 0 auto;
  max-width: 1920px;
  width: 99%;
}

#content-wrapper {
  background: #e7ebee;
  height: 100%;
  margin-top: 0;
  margin-bottom: 0;
  position: relative;
  min-height: 1200px;
  padding: 15px 15px 35px 15px;
  margin-left: 220px;
}
@media (max-width: 991px) {
  #content-wrapper {
    margin-left: 0;
    border-left: 0 !important;
    border-right: 0 !important;
  }
}
@media (max-width: 767px) {
  #content-wrapper {
    padding: 10px 8px 0 8px;
  }
}
@media (max-width: 419px) {
  #content-wrapper {
    padding: 5px 5px 0 5px;
  }
}
#content-wrapper > .row {
  opacity: 1;
  /*transition: opacity 0.8s ease-in-out 0s;*/
}

.main-box {
  background: #FFFFFF;
  box-shadow: 1px 1px 2px 0 #CCCCCC;
  margin-bottom: 16px;
  /* overflow: hidden; */
  border-radius: 3px;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
}
@media (max-width: 767px) {
  .main-box {
    margin-bottom: 35px;
  }
}
.main-box h2 {
  font-size: 1.3em;
  line-height: 29px;
  margin: 0;
  padding: 0;
}
@media (max-width: 419px) {
  .main-box h2 {
    margin-bottom: 5px;
  }
}
.main-box.no-header {
  padding-top: 20px;
}
.main-box .main-box-header {
  min-height: 50px;
  padding: 10px 10px;
}
.main-box .main-box-header.with-border {
  border-bottom: 1px solid #ecf0f1;
}
.main-box .main-box-body {
  padding: 0 20px 20px 20px;
}

h1 small,
h2 small,
h3 small,
h1 .small,
h2 .small,
h3 .small {
  padding-left: 8px;
}

.navbar-toggle {
  border: medium none;
  font-size: 1.4em;
  height: 50px;
  margin: 0;
  text-shadow: none;
  width: 50px;
  z-index: 100;
  border-radius: 0;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
}
.navbar-toggle .icon-bar {
  background: none repeat scroll 0 0 white;
}

.nav > li {
  float: left;
}

.navbar-nav {
  margin: 0 0 0 10px;
}
.navbar-nav > li > a {
  padding-bottom: 15px;
  padding-top: 15px;
  line-height: 24px;
}
.navbar-nav > li > .dropdown-menu {
  border-radius: 3px;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
  min-width: 223px;
}

.dropdown-menu > li > div > a,
.dropdown-menu > li > a {
  color: #707070;
  font-size: 0.875em;
  line-height: 1.7;
  padding-left: 35px;
  -webkit-transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s;
          transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s;
}
.dropdown-menu > li > a:hover, .dropdown-menu > li > a:focus {
  background-color: #f6f6f6;
  color: #707070;
}
.dropdown-menu > li > a > i {
  position: absolute;
  margin-left: -18px!important;
  margin-top: 4px!important;
}

.nav-pills > li {
  float: none;
}
.nav-pills > li > a {
  border-radius: 0;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
}

.navbar > .container .navbar-brand {
  background: #003466;
  color: #fff;
  font-family: 'Titillium Web',Geneva,sans-serif;
  font-weight: 700;
  width: 220px;
  margin-left: -8px;
  padding: 10px 15px;
}
@media (max-width: 991px) {
  .navbar > .container .navbar-brand {
    background: transparent;
    color: #2980b9;
  }
}
@media (max-width: 767px) {
  .navbar > .container .navbar-brand {
    padding-top: 12px;
    padding-top: 12.5px;
  }
}

@media (max-width: 767px) {
  #logo {
    width: 150px;
    padding-left: 0;
    font-size: 1em;
    margin-left: 6px;
  }
}
@media (max-width: 400px) {
  #logo {
    width: auto;
    margin-left: 15px;
  }
}
@media (max-width: 767px) {
  #logo img {
    height: 19px;
  }
}
@media (max-width: 400px) {
  #logo span {
    display: none;
  }
}
#logo.navbar-brand > img {
  float: left;
  padding-right: 4px;
  height: 30px;
}
@media (max-width: 767px) {
  #logo.navbar-brand > img {
    height: 25px;
  }
}
@media (min-width: 992px) {
  #logo.navbar-brand > img.normal-logo.logo-white {
    display: block;
  }
}
@media (max-width: 991px) {
  #logo.navbar-brand > img.normal-logo.logo-white {
    display: none;
  }
}
@media (min-width: 992px) {
  #logo.navbar-brand > img.normal-logo.logo-black {
    display: none;
  }
}
@media (max-width: 991px) {
  #logo.navbar-brand > img.normal-logo.logo-black {
    display: block;
  }
}

#header-navbar {
  background:  #003466;
  border: 0 none;
  border-radius: 0;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
  margin: 0;
  min-height: 50px;
  color: #262626;
  box-shadow: 0px 1px 3px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  z-index: 99;
}
#header-navbar .navbar-form .form-group {
  position: relative;
}
#header-navbar .navbar-form .form-control {
  background: #131313;
  color: #707070;
  height: 30px;
  line-height: 30px;
  margin-top: 2px;
  font-size: 0.75em;
}
#header-navbar .navbar-form .nav-search-icon {
  position: absolute;
  color: #707070;
  right: 6px;
  top: 8px;
}
#header-navbar .nav > li > a {
  height: 50px;
}
#header-navbar .nav > li > a > span.count {
  background: none repeat scroll 0 0 #e74c3c;
  border-radius: 50%;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
  color: #fff;
  display: block;
  font-size: 9px;
  height: 14px;
  line-height: 14px;
  position: absolute;
  right: 10px;
  text-align: center;
  top: 11px;
  width: 14px;
}
#header-navbar .profile-dropdown > a {
  padding-top: 8px;
  padding-bottom: 7px;
  line-height: 35px;
}
#header-navbar .profile-dropdown > a > img {
  border-radius: 50%;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
  float: left;
  height: 35px;
  margin-right: 5px;
  width: 35px;
}
#header-navbar .profile-dropdown > a > span {
  float: left;
  display: block;
  margin-right: 3px;
  font-size: em;
}

#header-nav .form-control {
  border-radius: 0;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
  border: 0;
}

#header-navbar .navbar-left .navbar-nav {
  margin-left: 0;
}

#header-navbar .nav > li > a {
  font-size: 0.875em;
  padding-left: 18px;
  padding-right: 18px;
  color: #ffffff;
  border: none;
  border-radius: 0;
  background-clip: padding-box;
  /* stops bg color from leaking oцшutside the border: */
}

#header-navbar .nav > li > a > i,
#sidebar-nav .nav > li > a > i {
  font-size: 1.125em;
}
#sidebar-nav .nav > li > a:focus,
#sidebar-nav .nav .open > a,
#sidebar-nav .nav .open > a:focus {
  background: inherit;
}

#sidebar-nav .nav > li > a:hover,
#sidebar-nav .nav .open > a:hover {
  background: #1a1d22;
  color: #fff;
  outline: none;
}

#header-navbar .nav > li > a:hover,
#header-navbar .nav > li > a:focus,
#header-navbar .nav .open > a,
#header-navbar .nav .open > a:hover,
#header-navbar .nav .open > a:focus,
.navbar-toggle:hover,
.navbar-toggle:focus,
.mobile-search.active > .btn {
  background: #ff6600;
  color: #fff;
}

#header-navbar .nav > li > a:hover,
#header-navbar .nav > li > a:focus,
#header-navbar .nav .open > a,
#header-navbar .nav .open > a:hover,
#header-navbar .nav .open > a:focus {
  background-color: #ff6600;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus,
#sidebar-nav .nav-pills > li.active > a,
#sidebar-nav .nav-pills > li.active > a:hover,
#sidebar-nav .nav-pills > li.active > a:focus,
.nav-small #nav-col #sidebar-nav .nav-pills > li.open > a {
  background-color: #1a1d22;
  color: #fff;
  border-left-color: #ff6600;
}

#header-navbar .nav > li > a:hover,
#header-navbar .nav > li > a:focus,
#header-navbar .nav .open > a,
#header-navbar .nav .open > a:hover,
#header-navbar .nav .open > a:focus,
.navbar-toggle:hover,
.navbar-toggle:focus,
.mobile-search.active > .btn {
  background: #ff6600;
  color: #fff;
}

#header-navbar .nav > li > a:hover,
#header-navbar .nav > li > a:focus,
#header-navbar .nav .open > a,
#header-navbar .nav .open > a:hover,
#header-navbar .nav .open > a:focus {
  background-color: #ff6600;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus,
#sidebar-nav .nav-pills > li.active > a,
#sidebar-nav .nav-pills > li.active > a:hover,
#sidebar-nav .nav-pills > li.active > a:focus,
.nav-small #nav-col #sidebar-nav .nav-pills > li.open > a {
  background-color: #1a1d22;
  color: #fff;
  border-left-color: #ff6600;
}

#header-navbar .nav > li .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}

#header-navbar .nav a:hover .caret {
  border-top-color: #fff;
  border-bottom-color: #fff;
}

.theme-whbl-v2 .navbar > .container .navbar-brand{
  background-color: #1c5e8d;
}

.theme-whbl-v2 #header-navbar{
  background-color: #1c5e8d;
}
.theme-whbl-v3 #header-navbar{
  background-color: #003466;
}
.theme-whbl-v3 .navbar-toggle{
  color: #ffffff;
}

.drowdown-search {
  background: #FFFFFF;
  display: block;
  left: 168px;
  padding: 4px 0;
  position: absolute;
  top: 0;
  -webkit-transition: left 0.25s ease-out 0s, right 0.25s ease-out 0s;
          transition: left 0.25s ease-out 0s, right 0.25s ease-out 0s;
  width: 0;
  z-index: 1;
  overflow: hidden;
}
@media (max-width: 991px) {
  .drowdown-search {
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.3);
    display: block;
    left: 0;
    padding: 10px 0;
    top: 50px;
    width: 100%;
    display: none;
    -webkit-transition: none;
            transition: none;
  }
}
.drowdown-search form .form-group {
  position: relative;
  margin: 0 15px;
}
.drowdown-search form .form-control {
  background: none repeat scroll 0 0 #FFFFFF;
  box-shadow: none !important;
  color: #e1e1e1;
  font-size: 1em;
  font-weight: 400;
  height: 42px;
  line-height: 42px;
  padding-left: 5px;
  padding-right: 26px;
}
@media (max-width: 991px) {
  .drowdown-search form .form-control {
    font-size: 1.6em;
    font-weight: 600;
  }
}
.drowdown-search form .nav-search-icon {
  color: #e1e1e1;
  font-size: 1.2em;
  position: absolute;
  right: 5px;
  top: 13px;
  cursor: pointer;
}
@media (max-width: 991px) {
  .drowdown-search form .nav-search-icon {
    font-size: 1.8em;
    right: 15px;
    top: 8px;
  }
}
.drowdown-search form .form-control::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #e1e1e1;
}
.drowdown-search form .form-control:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #e1e1e1;
}
.drowdown-search form .form-control::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #e1e1e1;
}
.drowdown-search form .form-control:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #e1e1e1;
}

.mobile-search {
  overflow: hidden;
}
@media (max-width: 991px) {
  .mobile-search {
    position: inherit !important;
    overflow: visible;
  }
}
.mobile-search > a.btn {
  float: right;
}
.mobile-search.active {
  width: 220px;
}
@media (max-width: 991px) {
  .mobile-search.active {
    width: auto;
  }
}
.mobile-search.active .drowdown-search {
  width: 220px;
  left: 0;
}
@media (max-width: 991px) {
  .mobile-search.active .drowdown-search {
    display: block;
    width: 100%;
    left: 0;
  }
}

.navbar-nav > li > .dropdown-menu.notifications-list {
  min-width: 320px;
  padding-bottom: 0;
  padding-top: 0;
  margin-top: 8px;
  color: #707070;
  margin-left: -265px;
}

.notifications-list .item-header {
  font-size: 0.875em;
  font-weight: bold;
  line-height: 40px;
  text-align: center;
}
.notifications-list .item {
  border-top: 1px solid #f6f6f6;
  line-height: 1.4;
  padding-bottom: 0;
  padding-top: 0;
  clear: both;
}
.notifications-list .item > a > i {
  color: #e74c3c;
}
.notifications-list .item a {
  clear: both;
  white-space: normal;
  padding-bottom: 8px;
  padding-top: 8px;
}
.notifications-list .item a .time {
  color: #ff6600;
  margin-left: 10px;
  position: absolute;
  right: 13px;
  white-space: normal !important;
}
.notifications-list .item a .time i {
  margin-right: 3px;
}
.notifications-list .item-footer {
  background: #ff6600;
  padding-bottom: 0;
  padding-top: 0;
}
.notifications-list .item-footer a {
  padding: 8px 20px;
  text-align: center;
  -webkit-transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s;
          transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s;
  color: #fff;
}
.notifications-list .item-footer a:hover, .notifications-list .item-footer a:focus {
  background-color: #2980b9;
  color: #fff;
}
.notifications-list .pointer {
  height: 12px;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 21px;
  top: -12px;
  width: 12px;
}
.notifications-list .pointer .pointer-inner {
  position: relative;
}
.notifications-list .pointer .arrow {
  border-color: transparent transparent #FFFFFF;
  border-style: solid;
  border-width: 6px;
  cursor: pointer;
  left: auto;
  position: absolute;
  right: 0;
  top: 0;
  z-index: 1002;
}
.notifications-list .pointer .arrow-border {
  border-color: transparent transparent rgba(0, 0, 0, 0.15);
  border-style: solid;
  border-width: 7px;
  cursor: pointer;
  left: -1px;
  position: absolute;
  top: -2px;
  z-index: 1001;
}

.messages-list .item.first-item {
  border-top: 0 !important;
}
.messages-list .item > a {
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 25px;
  -webkit-transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s;
          transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s;
}
.messages-list .item > a > img {
  position: absolute;
  margin-top: 10px;
}
.messages-list .item > a > .content {
  display: block;
  padding-left: 50px;
  padding-top: 5px;
}
.messages-list .item > a > .content .content-headline {
  color: #605F5F;
  display: block;
  font-weight: 600;
}
.messages-list .item > a > .content .content-text {
  display: block;
  line-height: 1.4;
}

@media (max-width: 400px) {
  #header-navbar .container {
    padding: 0;
  }
}

@media (max-width: 400px) {
  .profile-dropdown .dropdown-toggle {
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
}

@media (max-width: 400px) {
  #header-nav .nav {
    margin-left: 0;
  }
}

#col-left {
  position: relative;
  color: #e1e1e1;
  height: 100%;
}
#col-left a {
  color: #e1e1e1;
}
#col-left a:hover,
#col-left .nav-active a.nav-link,
#col-left a.active {
  color: #fff;
}

#nav-col {
  padding: 0;
  z-index: 100;
  position: absolute;
  background: #2c3e50;
  width: 220px;
}

.theme-whbl-v3 #nav-col{
  background: #e8e8e8;
}

.theme-whbl-v3 #sidebar-nav .nav > li > a {
  background: #003366;
}

@media (max-width: 991px) {
  #nav-col {
    position: relative;
    width: auto;
  }
}

#sidebar-nav {
  max-height: 100%;
  padding-left: 0;
  padding-right: 0;
}
#sidebar-nav .nav > li {
  margin: 0;
  /* font-size: 0.875em;
  font-weight: 600; */
}
#sidebar-nav .nav > li > a {
  color: #fff;
  height: 44px;
  line-height: 28px;
  -webkit-transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s, box-shadow 0.1s ease-in-out 0s;
  transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s, box-shadow 0.1s ease-in-out 0s;
  overflow: hidden;
  padding: 8px 15px 8px 20px;
  border-left: 4px solid transparent;
}
#sidebar-nav .nav > li > a:hover {
  border-left-color: #ff6600;
}
#sidebar-nav .nav > li.open > a {
  border-bottom-color: #252525;
  outline: none;
  text-decoration: none;
}
#sidebar-nav .nav > li > a > i {
  position: absolute;
  margin-top: 6px;
}
#sidebar-nav .nav > li > a > span {
  margin-left: 35px;
  font-size: 0.875em;
}
#sidebar-nav .nav > li > a > span.label {
  font-size: 0.75em;
  margin: 5px 0 0 0;
  padding: 4px 0.6em;
}
#sidebar-nav .nav > li > a > span.label.label-circle {
  margin-right: 5px;
}
#sidebar-nav .nav li > a.dropdown-toggle > .drop-icon {
  color: #868b98;
  font-size: 12px;
  margin-top: -6px;
  position: absolute;
  right: 25px;
  top: 50%;
  -webkit-transition: -webkit-transform 0.2s ease-in-out 0.1s;
  transition: transform 0.2s ease-in-out 0.1s;
}
#sidebar-nav .nav li.open > a.dropdown-toggle > .drop-icon,
#sidebar-nav .nav li.active > a.dropdown-toggle > .drop-icon {
  color: #fff;
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
#sidebar-nav .nav li .submenu {
  display: none;
  background: #1a1d22;
  padding: 5px 0;
  margin: 0;
  list-style: none;
}
#sidebar-nav .nav li.active > .submenu {
  display: block;
}
#sidebar-nav .nav li.active > .submenu,
#sidebar-nav .nav > li.active > .submenu > li.active > .submenu {
  display: block;
}
#sidebar-nav .nav li .submenu > li {
  position: relative;
}
#sidebar-nav .nav li .submenu > li > a {
  display: block;
  font-size: 0.875em;
  line-height: 38px;
  padding-left: 66px;
  color: #fff;
  cursor: pointer;
  outline: none;
  text-decoration: none;
  -webkit-transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s, box-shadow 0.1s ease-in-out 0s;
  transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s, box-shadow 0.1s ease-in-out 0s;
}
#sidebar-nav .nav li .submenu > li:first-of-type > a {
  border-top: 0;
}
#sidebar-nav .nav li .submenu > li > a:hover,
#sidebar-nav .nav li .submenu > li > a.active,
#sidebar-nav .nav li .submenu > li.active > a {
  text-decoration: none;
  color: #fff;
  background-color: #121418;
  /* box-shadow: 0 -1px 0 0 #8bf2e6 inset; */
}
#sidebar-nav .nav > .open > .submenu .submenu,
#sidebar-nav .nav > .active > .submenu .submenu {
  display: none;
}
#sidebar-nav .nav > .open > .submenu > li > a,
#sidebar-nav .nav > .active > .submenu > li > a {
  position: relative;
}
#sidebar-nav .nav > .open > .submenu > .open > a,
#sidebar-nav .nav > .active > .submenu > .open > a,
#sidebar-nav .nav > .active > .submenu > .active > a {
  background: inherit;
  border-bottom-color: #171717;
  box-shadow: 0 -1px 0 #171717 inset;
}
#sidebar-nav .nav > .open > .submenu > li > .submenu,
#sidebar-nav .nav > .active > .submenu > li > .submenu {
  background-color: #171717;
}
#sidebar-nav .nav > .open > .submenu > li > .submenu > li a:hover,
#sidebar-nav .nav > .active > .submenu > li > .submenu > li a:hover,
#sidebar-nav .nav > .active > .submenu > li > .submenu > li a.active {
  /* background-color: #232323; */
  color: #ff6600;
}
#sidebar-nav .nav > .open > .submenu > li > .submenu a,
#sidebar-nav .nav > .active > .submenu > li > .submenu a {
  border-bottom: 0 none;
  border-top: 0 none;
  padding-left: 85px;
}
#sidebar-nav .nav > .open > .submenu > li > .submenu a:before,
#sidebar-nav .nav > .active > .submenu > li > .submenu a:before {
  content: "\f111";
  display: inline;
  font-family: FontAwesome;
  font-size: 4px;
  font-style: normal;
  font-weight: normal;
  margin-left: -10px;
  margin-top: 1px;
  position: absolute;
}
#sidebar-nav .nav > .open > .submenu > li > a.dropdown-toggle > .drop-icon,
#sidebar-nav .nav > .active > .submenu > li > a.dropdown-toggle > .drop-icon {
  font-size: 10px;
  margin-top: -5px;
}

.theme-whbl #sidebar-nav .nav-pills > li.active > a > div > i {
  color: #2980b9;
}

.theme-whbl #sidebar-nav .nav-pills > li.open > a > div > i {
  color: #2980b9;
}

#sidebar-nav .nav > .open > .submenu .submenu,
#sidebar-nav .nav > .active > .submenu .submenu {
  display: none;
}
#sidebar-nav .nav > .open > .submenu > li > a,
#sidebar-nav .nav > .active > .submenu > li > a {
  position: relative;
}
#sidebar-nav .nav > .open > .submenu > .open > a,
#sidebar-nav .nav > .active > .submenu > .open > a,
#sidebar-nav .nav > .active > .submenu > .active > a {
  background: inherit;
  border-bottom-color: #dbdee5;
  box-shadow: 0 -1px 0 #dbdee5 inset;
}
#sidebar-nav .nav > .open > .submenu > li > .submenu,
#sidebar-nav .nav > .active > .submenu > li > .submenu,
#sidebar-nav .nav > .active > .submenu > li.open {
  background-color: #dbdee5;
}
#sidebar-nav .nav > .open > .submenu > li > .submenu > li a:hover,
#sidebar-nav .nav > .active > .submenu > li > .submenu > li a:hover,
#sidebar-nav .nav > .active > .submenu > li > .submenu > li a.active {
  /* background-color: #232323; */
  color: #ff6600;

}
#sidebar-nav .nav > .open > .submenu > li > .submenu a,
#sidebar-nav .nav > .active > .submenu > li > .submenu a {
  border-bottom: 0 none;
  border-top: 0 none;
  padding-left: 85px;
}
#sidebar-nav .nav > .open > .submenu > li > .submenu a:before,
#sidebar-nav .nav > .active > .submenu > li > .submenu a:before {
  content: "\f111";
  display: inline;
  font-family: FontAwesome;
  font-size: 4px;
  font-style: normal;
  font-weight: normal;
  margin-left: -10px;
  margin-top: 1px;
  position: absolute;
}
#sidebar-nav .nav > .open > .submenu > li > a.dropdown-toggle > .drop-icon,
#sidebar-nav .nav > .active > .submenu > li > a.dropdown-toggle > .drop-icon {
  font-size: 10px;
  margin-top: -5px;
}

.navbar-nav .open .dropdown-menu {
  background-color: #FFFFFF;
  border: none;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
  left: 0;
  position: absolute;
}

#user-left-box {
  background: #fff;
  padding: 20px 15px;
}
#user-left-box img {
  border-radius: 50%;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
  /*float: left;*/
  width: 80px;
  margin: 0 auto;
}
#user-left-box .user-box {
  color: rgb(52, 152, 219);
  /*float: left;*/
  /*padding-left: 15px;*/
  padding-top: 11px;
}
#user-left-box .user-box > .name {
  display: block;
  font-size: 1.3em;
  font-weight: 600;
  line-height: 1.2;
  text-align: center;
}
#user-left-box .user-box > .status {
  display: block;
  font-size: 0.75em;
  padding-top: 3px;
}
#user-left-box .user-box > .status > i {
  color: #2ecc71;
  margin-right: 4px;
}

@media (min-width: 992px) {
  .nav-small #nav-col {
    width: 64px;
  }
  .nav-small #content-wrapper {
    margin-left: 64px;
  }
  .nav-small #nav-col #user-left-box {
    display: none;
  }
  .nav-small #nav-col #sidebar-nav .nav > li > a > span {
    display: none;
  }
  .nav-small #nav-col #sidebar-nav .nav li > a.dropdown-toggle > .drop-icon {
    display: none;
  }
  .nav-small #nav-col #sidebar-nav .nav .submenu > li > a.dropdown-toggle > .drop-icon {
    display: block;
  }
  .nav-small #nav-col #sidebar-nav .nav li .submenu {
    left: 64px;
    position: absolute;
    top: 0;
    width: 210px;
  }
  .nav-small #nav-col #sidebar-nav .nav li .submenu > li > a {
    padding-left: 28px;
  }
  .nav-small #nav-col #sidebar-nav .nav > .open > .submenu > li > .submenu,
  .nav-small #nav-col #sidebar-nav .nav > .active > .submenu > li > .submenu {
    left: auto;
    position: relative;
    top: auto;
    width: 100%;
  }
  .nav-small #nav-col #sidebar-nav .nav > .open > .submenu > li > .submenu a,
  .nav-small #nav-col #sidebar-nav .nav > .active > .submenu > li > .submenu a {
    padding-left: 48px;
  }
  .nav-small #sidebar-nav .nav li.active > .submenu {
    display: none;
  }
}
@media (max-width: 991px) {
  .navbar-toggle {
    display: block;
  }

  #sidebar-nav.navbar-collapse {
    max-height: 336px;
  }
}
#footer-bar {
  background: #fff;
  bottom: 0;
  font-size: 0.8em;
  height: 36px;
  line-height: 36px;
  margin-left: -15px;
  margin-right: -15px;
  position: absolute;
  width: 100%;
}
@media (max-width: 767px) {
  #footer-bar {
    margin-left: -8px;
    margin-right: -8px;
  }
}
@media (max-width: 419px) {
  #footer-bar {
    margin-left: -5px;
    margin-right: -5px;
  }
}

#footer-copyright {
  text-align: center;
  margin: 0;
}

/* FIXED ELEMENTS */
.fixed-header #header-navbar {
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 999;
}
.fixed-header #page-wrapper {
  padding-top: 50px;
}

.fixed-footer #footer-bar {
  bottom: 0;
  left: 0;
  margin: 0;
  position: fixed;
  right: 0;
  width: 100%;
  z-index: 999;
}
.fixed-footer #content-wrapper {
  padding-bottom: 36px;
}

@media (min-width: 992px) {
  .fixed-leftmenu #nav-col {
    position: fixed;
    height: 100%;
  }

  .fixed-leftmenu.fixed-footer #nav-col {
    padding-bottom: 36px;
  }

  .fixed-leftmenu.fixed-header #nav-col {
    padding-bottom: 50px;
  }

  .fixed-leftmenu.fixed-header.fixed-footer #nav-col {
    padding-bottom: 86px;
  }
}
@media (max-width: 991px) {
  .fixed-header #nav-col {
    position: fixed;
    width: 100%;
  }
}
.boxed-layout #theme-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  position: relative;
}
.boxed-layout #page-wrapper {
  background-color: #e8ebf2;
}
.boxed-layout.fixed-header #header-navbar, .boxed-layout.fixed-footer #footer-bar {
  max-width: 1200px;
  margin: 0 auto;
}

@media (max-width: 1200px) {
  .boxed-layout #theme-wrapper,
  .boxed-layout.fixed-header #header-navbar,
  .boxed-layout.fixed-footer #footer-bar {
    max-width: 1140px;
  }
}
@media (max-width: 1199px) {
  .boxed-layout #theme-wrapper,
  .boxed-layout.fixed-header #header-navbar,
  .boxed-layout.fixed-footer #footer-bar {
    max-width: 1024px;
  }
}
/* BACKGROUNDS */
.emerald-bg {
  background-color: #ff6600 !important;
}

.red-bg {
  background-color: #e74c3c !important;
}

.yellow-bg {
  background-color: #f1c40f !important;
}

.green-bg {
  background-color: #2ecc71 !important;
}

.purple-bg {
  background-color: #9b59b6 !important;
}

.gray-bg {
  background-color: #95a5a6 !important;
}

.white-bg {
  background-color: #ffffff !important;
}

/* COLORS */
.emerald {
  color: #ff6600 !important;
}

.red {
  color: #e74c3c !important;
}

.yellow {
  color: #f1c40f !important;
}

.green {
  color: #2ecc71 !important;
}

.purple {
  color: #9b59b6 !important;
}

.gray {
  color: #95a5a6 !important;
}

/* STATUS AND TEXT COLORS */
.status-green {
  color: #005826;
}

.status-red {
  color: #9e0b0f;
}

.txt-white {
  color: #fff !important;
}

.txt-white-hover:hover {
  color: #fff !important;
}

/* MARGINS */
.mrg-b-xs {
  margin-bottom: 3px;
}

.mrg-b-sm {
  margin-bottom: 6px;
}

.mrg-b-md {
  margin-bottom: 10px;
}

.mrg-b-lg {
  margin-bottom: 20px;
}

.mrg-t-xs {
  margin-top: 3px;
}

.mrg-t-sm {
  margin-top: 6px;
}

.mrg-t-md {
  margin-top: 10px;
}

.mrg-t-lg {
  margin-top: 20px;
}

.mrg-r-xs {
  margin-right: 3px;
}

.mrg-r-sm {
  margin-right: 6px;
}

.mrg-r-md {
  margin-right: 10px;
}

.mrg-r-lg {
  margin-right: 20px;
}

.mrg-l-xs {
  margin-left: 3px;
}

.mrg-l-sm {
  margin-left: 6px;
}

.mrg-l-md {
  margin-left: 10px;
}

.mrg-l-lg {
  margin-left: 20px;
}

/* BUTTONS */
.btn {
  border: none;
  padding: 6px 12px;
  -webkit-transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s;
          transition: border-color 0.1s ease-in-out 0s, background-color 0.1s ease-in-out 0s;
  outline: none;
  border-radius: 3px;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
}

.btn-default,
.wizard-cancel,
.wizard-back {
  background-color: #95a5a6;
  border-color: #7f8c8d;
  color: #fff;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default,
.wizard-cancel:hover,
.wizard-cancel:focus,
.wizard-cancel:active,
.wizard-cancel.active,
.wizard-back:hover,
.wizard-back:focus,
.wizard-back:active,
.wizard-back.active {
  background-color: #949e9f;
  border-color: #748182;
  color: #fff;
}

.btn-default .caret {
  border-top-color: #FFFFFF;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
  background-color: #7f8c8d;
  border-color: #5c6667;
}

.btn-info {
  background-color: #01b9fe;
  border-color: #0298d1;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
  background-color: #0298d1;
  border-color: #0178a5;
}

.btn-link {
  border: none;
}

.btn-primary {
  background-color: #ff6600;
  border-color: #2980b9;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
  background-color: #2980b9;
  border-color: #1c5c87;
}

.btn-success {
  background-color: #2ecc71;
  border-color: #27ae60;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
  background-color: #27ae60;
}

.btn-danger {
  background-color: #e74c3c;
  border-color: #c0392b;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
  background-color: #c0392b;
}

.btn-warning {
  background-color: #f1c40f;
  border-color: #f39c12;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
  background-color: #e0b50a;
  border-color: #bd9804;
}

.btn-facebook {
  background-color: #3b5998 !important;
  border-color: #2f477b !important;
}
.btn-facebook:hover {
  background-color: #2f477b !important;
  border-color: #263963 !important;
}

.btn-twitter {
  background-color: #00aced !important;
  border-color: #0098d1 !important;
}
.btn-twitter:hover {
  background-color: #0098d1 !important;
  border-color: #0283b4 !important;
}

.icon-box {
  margin-top: 5px;
}
.icon-box .btn {
  border: 1px solid #E8EBF2;
  margin-left: 3px;
  margin-right: 0;
}
.icon-box .btn:hover {
  background-color: #eee;
  color: #ff6600;
}

/* LABELS */
.label {
  border-radius: 3px;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
  font-size: 0.875em;
  font-weight: 600;
}

.label-default,
.fc-event.label-default {
  background-color: #95a5a6;
}

.label-primary,
.fc-event.label-primary {
  background-color: #ff6600;
}

.label-success,
.fc-event.label-success {
  background-color: #2ecc71;
}

.label-info,
.fc-event.label-info {
  background-color: #9b59b6;
  /*#2b9db6;*/
}

.label-warning,
.fc-event.label-warning {
  background-color: #f1c40f;
}

.label-danger,
.fc-event.label-danger {
  background-color: #e74c3c;
}

.label.label-large {
  font-size: 1em;
  padding: 0.4em 0.8em 0.5em;
}

.label.label-circle {
  border-radius: 50%;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
  padding: 4px !important;
}

#calendar .fc-event,
.external-event {
  border-left: 4px solid #2980b9;
}

#calendar .fc-event.label-success,
.external-event.label-success {
  border-left: 4px solid #27ae60;
}

#calendar .fc-event.label-warning,
.external-event.label-warning {
  border-left: 4px solid #f39c12;
}

#calendar .fc-event.label-danger,
.external-event.label-danger {
  border-left: 4px solid #c0392b;
  color: #fff;
}

#calendar .fc-event.label-info,
.external-event.label-info {
  border-left: 4px solid #8e44ad;
}

#calendar .fc-event.label-default,
.external-event.label-default {
  border-left: 4px solid #7f8c8d;
}

@media only screen and (max-width: 440px) {
  #calendar .fc-header-right,
  #calendar .fc-button-today,
  #calendar .fc-header-space {
    display: none;
  }
}
/* ALERTS */
.alert {
  border-radius: 0;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
  -webkit-border-image: none;
       -o-border-image: none;
          border-image: none;
  border-style: none none none solid;
  border-width: 0 0 0 4px;
}

.alert-success {
  border-color: #27ae60;
  color: #27ae60;
}

.alert-warning {
  border-color: #f39c12;
  color: #f39c12;
}

.alert-danger {
  border-color: #c0392b;
  color: #c0392b;
}

.alert-info {
  border-color: #2980b9;
  color: #2980b9;
}
.alert-info .alert-link {
  color: #2980b9;
}

/* PAGINATION */
.pagination {
  margin: 5px 5px;
}
.pagination > li:first-child > a,
.pagination > li:first-child > span,
.pagination > li:last-child > a,
.pagination > li:last-child > span {
  border-radius: 3px;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
}
.pagination > li > a,
.pagination > li > span,
.pagination > li > a:hover,
.pagination > li > span:hover,
.pagination > li > a:focus,
.pagination > li > span:focus,
.pagination > li > a:active,
.pagination > li > span:active {
  color: #ff6600;
}
.pagination > .active > a,
.pagination > .active > span,
.pagination > .active > a:hover,
.pagination > .active > span:hover,
.pagination > .active > a:focus,
.pagination > .active > span:focus {
  background-color: #ff6600;
  border-color: #ff6600;
}

/* ITEMS */
a.list-group-item.active,
a.list-group-item.active:hover,
a.list-group-item.active:focus {
  background-color: #ff6600;
  border-color: #ff6600;
}

/* BADGES */
.badge.badge-danger {
  background-color: #e74c3c;
}

.badge.badge-success {
  background-color: #2ecc71;
}

.badge.badge-warning {
  background-color: #f1c40f;
}

.badge.badge-primary {
  background-color: #ff6600;
}

.badge.badge-info {
  background-color: #9b59b6;
}

/* TABS */
.nav-tabs {
  background: #d0d8de;
  border-color: transparent;
  border-radius: 3px 3px 0 0;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
}
.nav-tabs > li > a {
  border-radius: 0;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
  font-size: 1.125em;
  font-weight: 300;
  outline: none;
  color: #555;
  margin-right: 3px;
}
.nav-tabs > li > a:hover,
.nav-tabs > li > a:focus {
  border-color: transparent;
  border-radius: 3px 3px 0 0;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
  background: #b8c1c7;
  color: #555;
}
.nav-tabs > li.open > a,
.nav-tabs > li.open > a:hover,
.nav-tabs > li.open > a:focus {
  border-color: transparent;
}
.nav-tabs > li.open > a,
.nav-tabs > li.open > a:hover,
.nav-tabs > li.open > a:focus {
  border-radius: 3px 3px 0 0;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
}

.nav .open > a,
.nav .open > a:hover,
.nav .open > a:focus {
  border-color: #e7ebee;
}

.nav .caret {
  border-bottom-color: #ff6600;
  border-top-color: #ff6600;
}

.main-box .tabs-header {
  min-height: 50px;
  padding: 10px 20px;
}

.main-box .tab-content-body {
  padding: 0 20px 20px 20px;
  margin-bottom: 0;
}

.tabs-wrapper.tabs-no-header .tab-content {
  padding: 0 20px 20px;
}

/* PANEL */
.panel-default > .panel-heading {
  background-color: #ff6600;
  border-color: #ff6600;
  color: #FFFFFF;
  border-radius: 0;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
}

.panel-default > .panel-heading a:focus {
  color: #fff;
}

.panel-group .panel {
  border-radius: 0;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
  border: 0 none;
}

.panel-collapse {
  border: 1px solid #E8EBF2;
  border-top: 0;
}

.accordion .panel-title > a {
  display: block;
  position: relative;
  outline: none;
  text-decoration: none;
  color: #FFFFFF;
  padding-right: 15px;
}
.accordion .panel-title > a:hover {
  text-decoration: none;
  color: #FFFFFF;
}
.accordion .panel-title > a:after {
  content: "\f068";
  font-family: FontAwesome;
  font-style: normal;
  font-weight: normal;
  text-decoration: inherit;
  margin-top: -5px;
  font-size: 0.75em;
  position: absolute;
  right: 0;
  top: 50%;
}
.accordion .panel-title > a.accordion-toggle.collapsed:after {
  content: "\f067";
}
.accordion .panel-title > a.accordion-toggle > i {
  width: 24px;
  text-align: center;
  padding-right: 6px;
}


/* BREADCRUMBS */
.breadcrumb {
  background: none;
  margin-bottom: 0;
}
.breadcrumb > li {
  text-transform: uppercase;
  color: #262626;
  margin-left: 8px;
}
.breadcrumb > li span {
  padding-left: 8px;
}
.breadcrumb > li a {
  color: #262626;
}
.breadcrumb > li + li:before {
  color: #262626;
  font-family: FontAwesome;
  content: "\f105";
}
.breadcrumb > .active {
  font-weight: 600;
  text-transform: none;
  color: #262626;
}
.breadcrumb > li:first-child {
  margin-left: 0;
}


/* USER LIST TABLE */
.user-list tbody td > img {
  position: relative;
  max-width: 50px;
  float: left;
  margin-right: 15px;
}
.user-list tbody td .user-link {
  display: block;
  font-size: 1.25em;
  padding-top: 3px;
  margin-left: 60px;
}
.user-list tbody td .user-subhead {
  font-size: 0.875em;
  font-style: italic;
}

/* FORMS */
.form-group {
  margin-bottom: 20px;
}

label {
  font-weight: 400;
  font-size: 0.875em;
}

.form-control {
  border-radius: 3px;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
  border-color: #e7ebee;
  box-shadow: none;
  font-size: 13px;
}
.form-control:focus {
  border-color: rgba(52, 151, 219, 0.6);
  outline: 0;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 8px rgba(52, 152, 219, 0.6);
}

.form-inline-box {
  background: none repeat scroll 0 0 #F6F6F6;
  border: 1px solid #e7ebee;
  padding: 7px;
}

.radio {
  padding-left: 2px;
}
.radio label {
  display: inline-block;
  cursor: pointer;
  position: relative;
  padding-left: 30px;
  padding-right: 10px;
  padding-bottom: 5px;
}

input[type="radio"] {
  line-height: normal;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
}

.radio input[type=radio],
.radio-inline input[type=radio] {
  float: left;
  margin-left: -20px;
}

.radio input[type=radio] {
  display: none;
}

.radio label:before {
  background: none repeat scroll 0 0 #ffffff;
  border: 1px solid #e7ebee;
  border-radius: 50%;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
  content: "";
  display: inline-block;
  height: 20px;
  left: 0;
  margin-right: 10px;
  position: absolute;
  width: 20px;
}

.radio label:after {
  background-color: #ff6600;
  border: 1px solid #ff6600;
  border-radius: 50%;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
  content: "";
  display: inline-block;
  font-size: 13px;
  height: 16px;
  left: 2px;
  position: absolute;
  text-align: center;
  top: 2px;
  -webkit-transform: scale(0);
      -ms-transform: scale(0);
          transform: scale(0);
  width: 16px;
}

.radio input[type=radio]:checked + label:before,
.radio input[type=radio]:hover + label:before {
  border: 1px solid #ff6600;
}

.radio input[type=radio]:checked + label:after {
  line-height: 16px;
  -webkit-transform: scale(0.63);
      -ms-transform: scale(0.63);
          transform: scale(0.63);
  -webkit-transition: -webkit-transform 0.1s 0s cubic-bezier(0.4, 0.02, 0.3, 1.2);
          transition: transform 0.1s 0s cubic-bezier(0.4, 0.02, 0.3, 1.2);
}

.has-success .form-control {
  border-color: #2ecc71;
}
.has-success .help-block,
.has-success .control-label {
  color: #27ae60;
}
.has-success .form-control:focus {
  border-color: #27ae60;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #27ae60;
}
.has-success .input-group-addon {
  color: #2ecc71;
  background-color: #dff0d8;
  border-color: #2ecc71;
}

.has-warning .form-control {
  border-color: #f1c40f;
}
.has-warning .help-block,
.has-warning .control-label {
  color: #f39c12;
}
.has-warning .form-control:focus {
  border-color: #f39c12;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #f39c12;
}
.has-warning .input-group-addon {
  color: #f1c40f;
  background-color: #fcf8e3;
  border-color: #f1c40f;
}

.has-error .form-control {
  border-color: #e74c3c;
}
.has-error .help-block,
.has-error .control-label {
  color: #c0392b;
}
.has-error .form-control:focus {
  border-color: #c0392b;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075), 0 0 6px #c0392b;
}
.has-error .input-group-addon {
  color: #e74c3c;
  background-color: #f2dede;
  border-color: #e74c3c;
}

.input-group > .form-control,
.input-group > .input-group-addon,
.input-group > .input-group-btn > .btn {
  line-height: 1;
}

.input-group > .input-group-btn > .btn {
  border: 0;
  height: 34px;
}

.input-group-addon {
  background: transparent;
  border-color: #e7ebee;
}

.dropdown-menu {
  border-radius: 3px;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
}

.help-block {
  font-size: 0.875em;
}

.checkbox-inline > input {
  margin-top: 0;
}

.dropdown-menu > .active > a,
.dropdown-menu > .active > a:hover,
.dropdown-menu > .active > a:focus {
  background-color: #ff6600;
}

.bootstrap-timepicker-widget table td input {
  border: 1px solid #ccc;
  width: 30px !important;
  height: 30px;
}

.form-group-select2 label {
  display: block;
}

.select2-results .select2-result-label {
  font-size: 0.875em;
}

.select2-container .select2-choice {
  font-size: 0.875em;
}

.select2-container.select2-container-multi .select2-choices .select2-search-field input {
  font-size: 0.875em;
  font-family: 'Open Sans', sans-serif;
  background-color: #FFFFFF;
  color: #555555 !important;
  display: block;
  font-size: 13px;
  height: 34px;
  padding: 6px 12px;
  -webkit-transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
          transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  vertical-align: middle;
}

.select2-container.select2-container-multi .select2-choices {
  border-color: #ccc;
}

.select2-container-multi .select2-choices {
  background-image: none !important;
  border-color: #e7ebee !important;
  border-radius: 3px;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
}

.select2-container.select2-container-multi .select2-choices .select2-search-choice {
  background: none repeat scroll 0 0 #ff6600;
  border: medium none;
  border-radius: 4px;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
  box-shadow: none;
  color: #fff;
  font-size: 0.875em;
  font-weight: 400;
  line-height: 20px;
  margin: 5px 0 5px 5px;
}

.select2-container.select2-container-multi .select2-search-choice-close {
  top: 8px;
}

.select2-container-active .select2-choice,
.select2-container-active .select2-choices {
  border-color: rgba(52, 151, 219, 0.6) !important;
  border-radius: 3px 3px 0 0;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
}

.select2-container .select2-choice {
  border-radius: 0 !important;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
  background: #fff !important;
  border-color: #e7ebee !important;
  height: 34px !important;
  line-height: 34px !important;
}

.select2-container .select2-choice .select2-arrow {
  border-radius: 0 !important;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
  background: #eee !important;
  border-color: #e7ebee !important;
  width: 24px !important;
}

.select2-container .select2-choice .select2-arrow b {
  background-position: 3px 4px !important;
}

.select2-dropdown-open.select2-drop-above .select2-choice,
.select2-dropdown-open.select2-drop-above .select2-choices {
  border-color: transparent rgba(52, 151, 219, 0.6) rgba(52, 151, 219, 0.6) !important;
  border-radius: 0 0 3px 3px;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
}

.select2-drop.select2-drop-above.select2-drop-active {
  border-top: 1px solid rgba(52, 151, 219, 0.6) !important;
}

.select2-drop-active {
  border-color: rgba(52, 151, 219, 0.6) rgba(52, 151, 219, 0.6) !important;
}

.select2-container .select2-choice > .select2-chosen {
  font-size: 13px;
}

.tt-query {
  box-shadow: none !important;
}

.wysiwyg-editor {
  max-height: 250px;
  height: 250px;
  background-color: #fff;
  border-collapse: separate;
  border: 1px solid #e7ebee;
  border-radius: 3px;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
  padding: 10px;
  -moz-box-sizing: content-box;
       box-sizing: content-box;
  overflow: scroll;
  outline: none;
}

.btn-toolbar.editor-toolbar {
  margin-bottom: 7px;
}

.checkbox-nice {
  position: relative;
  padding-left: 15px;
}
.checkbox-nice input[type=checkbox] {
  visibility: hidden;
}
.checkbox-nice label {
  padding-top: 3px;
}
.checkbox-nice.checkbox-inline > label {
  margin-left: 16px;
}
.checkbox-nice label:before {
  cursor: pointer;
  position: absolute;
  width: 22px;
  height: 22px;
  left: 1px;
  top: 1px;
  background: #ffffff;
  content: "";
  border: 1px solid #e7ebee;
  border-radius: 3px;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
}
.checkbox-nice label:after {
  opacity: 0;
  content: '';
  position: absolute;
  width: 12px;
  height: 7px;
  background: transparent;
  top: 7px;
  left: 6px;
  border: 3px solid #ff6600;
  border-top: none;
  border-right: none;
  -webkit-transform: rotate(-45deg);
      -ms-transform: rotate(-45deg);
          transform: rotate(-45deg);
}
.checkbox-nice label:hover::after {
  opacity: 0.3;
}
.checkbox-nice input[type=checkbox]:checked + label:after {
  opacity: 1;
}

/* FORM ON/OFF SWITCH */
.onoffswitch {
  position: relative;
  width: 90px;
  -moz-box-sizing: content-box;
       box-sizing: content-box;
  margin-right: 20px;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border: 1px solid #999999;
  border-radius: 20px;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
}

.onoffswitch-inner {
  width: 200%;
  margin-left: -100%;
}

.onoffswitch-inner:before, .onoffswitch-inner:after {
  float: left;
  width: 50%;
  height: 30px;
  padding: 0;
  line-height: 30px;
  font-size: 14px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
}

.onoffswitch-inner:before {
  content: "ON";
  padding-left: 10px;
  background-color: #ff6600;
  color: #FFFFFF;
}

.onoffswitch.onoffswitch-success .onoffswitch-inner:before {
  background-color: #2ecc71;
}

.onoffswitch.onoffswitch-danger .onoffswitch-inner:before {
  background-color: #e74c3c;
}

.onoffswitch.onoffswitch-warning .onoffswitch-inner:before {
  background-color: #f1c40f;
}

.onoffswitch-inner:after {
  content: "OFF";
  padding-right: 10px;
  background-color: #EEEEEE;
  color: #999999;
  text-align: right;
}

.onoffswitch-switch {
  width: 18px;
  margin: 6px;
  background: #FFFFFF;
  border: 1px solid #999999;
  border-radius: 20px;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
  position: absolute;
  top: 0;
  bottom: 0;
  right: 56px;
  -moz-box-sizing: content-box;
       box-sizing: content-box;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked + .onoffswitch-label .onoffswitch-switch {
  right: 0px;
}

/* AUTOCOMPLETE */
.typeahead,
.tt-query,
.tt-hint {
  width: 400px;
}
@media only screen and (max-width: 480px) {
  .typeahead,
  .tt-query,
  .tt-hint {
    width: 100%;
  }
}

.twitter-typeahead {
  display: block !important;
}

.typeahead {
  background-color: #fff;
}
.typeahead:focus {
  border: 2px solid #0097cf;
}

.tt-query {
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
}

.tt-hint {
  color: #999;
  padding-top: 5px;
  padding-left: 11px;
}

.tt-dropdown-menu {
  width: 422px;
  margin-top: 12px;
  padding: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.2);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
}
@media only screen and (max-width: 480px) {
  .tt-dropdown-menu {
    width: 100%;
  }
}

.tt-suggestion {
  padding: 3px 20px;
  font-size: 1em;
  line-height: 20px;
}
.tt-suggestion.tt-is-under-cursor {
  color: #fff;
  background-color: #ff6600;
}
.tt-suggestion p {
  margin: 0;
}

.gist {
  font-size: 12px;
}

.example-twitter-oss .tt-suggestion {
  padding: 8px 20px;
}
.example-twitter-oss .tt-suggestion + .tt-suggestion {
  border-top: 1px solid #ccc;
}
.example-twitter-oss .repo-language {
  float: right;
  font-style: italic;
  font-size: 12px;
}
.example-twitter-oss .repo-name {
  font-weight: bold;
}
.example-twitter-oss .repo-description {
  font-size: 12px;
}


/* NESTABLE LIST */
.dd {
  position: relative;
  display: block;
  list-style: none;
  font-size: 13px;
  line-height: 20px;
}
@media only screen and (min-width: 700px) {
  .dd {
    float: left;
    width: 48%;
  }
}

.dd-list {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  list-style: none;
}

.dd-list .dd-list {
  padding-left: 30px;
}

.dd-collapsed .dd-list {
  display: none;
}

.dd-item,
.dd-empty,
.dd-placeholder {
  display: block;
  position: relative;
  margin: 0;
  padding: 0;
  min-height: 20px;
  font-size: 13px;
  line-height: 20px;
}

.dd-handle-list {
  position: absolute;
  width: 30px;
  height: 30px;
  background: #E1E1E1;
  top: 0;
  left: 0;
  z-index: 1;
  font-size: 1em;
  color: #000000;
  text-align: center;
  border: 1px solid #E1E1E1;
  padding-top: 4px;
}

.dd-handle {
  background: none repeat scroll 0 0 #F6F6F8;
  border: 1px solid #e1e1e1;
  border-radius: 0;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
  color: #000000;
  display: block;
  font-size: 0.875em;
  font-weight: 700;
  height: 30px;
  margin: 5px 0;
  padding: 5px 10px;
  text-decoration: none;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
}
.dd-handle:hover {
  color: #ff6600;
  background: #fff;
}
.dd-handle .nested-links {
  float: right;
}
.dd-handle .nested-links .nested-link {
  margin: 0 2px;
}
.dd-handle .nested-links .nested-link:last-child {
  margin-right: 0;
}
.dd-handle .nested-links .nested-link i {
  font-size: 1.25em;
}

.dd-item-list .dd-handle {
  padding-left: 40px;
}

.dd-item > button {
  display: block;
  position: relative;
  cursor: pointer;
  float: left;
  width: 25px;
  height: 20px;
  margin: 5px 0;
  padding: 0;
  text-indent: 100%;
  white-space: nowrap;
  overflow: hidden;
  border: 0;
  background: transparent;
  font-size: 12px;
  line-height: 1;
  text-align: center;
  font-weight: bold;
}
.dd-item > button:before {
  content: '+';
  display: block;
  position: absolute;
  width: 100%;
  text-align: center;
  text-indent: 0;
}
.dd-item > button[data-action="collapse"]:before {
  content: '-';
}

.dd-placeholder,
.dd-empty {
  margin: 5px 0;
  padding: 0;
  min-height: 30px;
  background: #f2fbff;
  border: 1px dashed #b6bcbf;
  -moz-box-sizing: border-box;
       box-sizing: border-box;
}

.dd-empty {
  border: 1px dashed #bbb;
  min-height: 100px;
  background-color: #e5e5e5;
  background-image: -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), -webkit-linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-image: linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff), linear-gradient(45deg, #fff 25%, transparent 25%, transparent 75%, #fff 75%, #fff);
  background-size: 60px 60px;
  background-position: 0 0, 30px 30px;
}

.dd-dragel {
  position: absolute;
  pointer-events: none;
  z-index: 9999;
}
.dd-dragel > .dd-item .dd-handle {
  margin-top: 0;
}
.dd-dragel .dd-handle {
  box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
}

.nestable-lists {
  display: block;
  clear: both;
  padding: 30px 0;
  width: 100%;
}
.nestable-lists .dd-placeholder {
  background: #E9FDFB;
  border-color: #2980b9;
}

.dd-hover > .dd-handle {
  background: #2ea8e5 !important;
}

@media only screen and (min-width: 700px) {
  .dd + .dd {
    margin-left: 2%;
  }
}
/* LOGIN PAGE */
#login-page {
  background-color: #e7ebee;
}

#login-page-full {
  background-color: #e7ebee;
}

#login-box {
  width:450px;
  margin: 60px auto 20px;
  overflow: hidden;
  border-radius: 3px 3px 0 0;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
}
@media only screen and (max-width: 767px) {
  #login-box {
    margin-top: 20px;
  }
}
#login-box #login-box-header {
  height: 5px;
}
#login-box #login-box-header > div {
  height: 100%;
  width: 16.6667%;
  float: left;
}
#login-box #login-box-header .login-box-header-red {
  background: #e74c3c;
}
#login-box #login-box-header .login-box-header-green {
  background: #2ecc71;
}
#login-box #login-box-header .login-box-header-yellow {
  background: #f1c40f;
}
#login-box #login-box-header .login-box-header-purple {
  background: #9b59b6;
}
#login-box #login-box-header .login-box-header-blue {
  background: #ff6600;
}
#login-box #login-box-header .login-box-header-gray {
  background: #95a5a6;
}

#login-box-inner {
  background: #fff;
  border-radius: 0 0 3px 3px;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
  border: 1px solid #e1e1e1;
  border-bottom-width: 5px;
  padding: 40px 25px;
}
#login-box-inner.with-heading {
  padding-top: 20px;
}
#login-box-inner h4 {
  margin-top: 0;
  margin-bottom: 10px;
}
#login-box-inner .reset-pass-input {
  padding: 15px 0;
  margin-bottom: 0;
}

#login-logo {
  background: none repeat scroll 0 0 #34495e;
  color: #fff;
  display: block;
  font-size: 2em;
  font-weight: 400;
  padding: 35px 0;
  text-align: center;
}
#login-logo > img {
  display: block;
  height: 40px;
  margin: 0 auto;
}
#login-logo > span {
  display: block;
  font-size: 0.6em;
  font-weight: 300;
  text-transform: none;
}

#login-box .input-group {
  margin-bottom: 10px;
}
#login-box .input-group input {
  font-weight: 300;
}
#login-box .input-group .input-group-addon {
  padding-left: 0;
  padding-right: 0;
  min-width: 50px;
}
#login-box .input-group .input-group-addon i {
  color: #efefef;
}
#login-box #login-forget-link {
  display: block;
  font-size: 0.875em;
  text-align: right;
  margin-top: 3px;
}
#login-box #remember-me-wrapper {
  padding: 10px 0;
}
#login-box .btn {
  font-size: 1.125em;
  font-weight: 600;
  padding-bottom: 10px;
  padding-top: 10px;
  text-transform: uppercase;
  margin-top: 8px;
}
#login-box .form-group {
  margin-bottom: 7px;
}
#login-box .form-group .checkbox label {
  padding-left: 7px;
}
#login-box .form-group .checkbox input {
  margin-left: 0;
}
#login-box .btn-facebook,
#login-box .btn-twitter {
  text-transform: none;
  font-size: 1em;
  margin-bottom: 10px;
}
#login-box .social-text {
  margin: 0;
  padding: 15px 0;
  text-align: center;
  font-size: 0.875em;
}

#login-box-inner .input-group > .form-control,
#login-box-inner .input-group > .input-group-addon {
  height: 46px;
  padding-top: 0;
  padding-bottom: 0;
}
#login-box-inner .input-group > .input-group-addon {
  height: 44px;
}

#login-box-footer {
  text-align: center;
  font-size: 0.875em;
  margin-top: 10px;
}

#login-full-wrapper #login-box-footer {
  color: #fff;
}
#login-full-wrapper #login-box-footer a {
  color: #fff;
  text-decoration: underline;
}
#login-full-wrapper #login-box-footer a:hover {
  text-decoration: none;
}

#login-page .login-create,
#login-page-full .login-create {
  margin-bottom: 20px;
}


@media (max-height: 605px) {
  #login-full-wrapper {
    position: relative;
  }
}
@media (max-height: 621px) and (max-width: 767px) {
  #login-full-wrapper {
    position: relative;
  }
}

#login-full-wrapper #login-box {
  border: none;
}

#login-page-full .container {
  max-width: 1440px;
  margin: 0 auto;
}
#login-page-full .login-full-create {
  margin-right: 20px;
  line-height: 50px;
}

#login-full-left {
  margin-top: 50px;
  margin-bottom: 20px;
}
#login-full-left h1 {
  text-align: center;
  color: #363636;
  font-weight: 600;
  margin-bottom: 40px;
}
#login-full-left h2 {
  text-align: center;
  margin-top: 30px;
}
#login-full-left p.login-full-devices {
  margin-top: 30px;
}
#login-full-left .login-full-features {
  margin: 20px 0;
  padding: 0;
  list-style: none;
  text-align: center;
}
#login-full-left .login-full-features > li {
  display: inline-block;
  margin: 0 8px;
}
#login-full-left .login-full-features > li > i {
  display: block;
  text-align: center;
  font-size: 1.6em;
  margin-bottom: 4px;
}
#login-full-left .login-full-features > li > span {
  display: block;
  text-align: center;
  font-size: 0.845em;
  line-height: 1.2;
}

@media (max-height: 605px) {
  #login-full-wrapper.reset-password-wrapper {
    position: absolute;
  }
}
@media (max-height: 505px) {
  #login-full-wrapper.reset-password-wrapper {
    position: relative;
  }
}


/* GRAPH BOX */
.graph-box {
  padding: 15px 15px;
  border-radius: 3px 0 0 3px;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
}
.graph-box h2 {
  color: #fff;
  font-size: 1.6em;
  font-weight: 300;
  margin-bottom: 10px;
  margin-left: 10px;
}
.graph-box .graph .morris-hover.morris-default-style {
  background: #000;
  background: rgba(0, 0, 0, 0.8);
  border: none;
  border-radius: 6px;
  background-clip: padding-box;
  /* stops bg color from leaking outside the border: */
  color: #999;
}

.graph-nice-legend {
  padding-left: 15px;
  padding-top: 65px;
}
@media (max-width: 1199px) {
  .graph-nice-legend {
    padding-left: 0;
  }
}
@media (max-width: 991px) {
  .graph-nice-legend {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 0;
  }
}
.graph-nice-legend .graph-legend-row {
  padding-top: 10px;
  padding-bottom: 10px;
}
.graph-nice-legend .graph-legend-name {
  display: block;
  font-size: 1.2em;
  font-weight: 300;
}
.graph-nice-legend .graph-legend-value {
  display: block;
  font-size: 2.1em;
  font-weight: 600;
  /* color: $primary-color; */
}
@media (max-width: 991px) {
  .graph-nice-legend .graph-legend-row-inner {
    padding: 0 20px;
  }
}

/* SMALL GRAPH BOX */
.small-graph-box {
  padding: 20px;
  color: #fff;
}
.small-graph-box .headline {
  display: block;
  font-size: 0.875em;
  font-weight: 400;
  margin-top: -5px;
  padding-bottom: 5px;
  text-transform: uppercase;
}
.small-graph-box .value {
  display: block;
  font-size: 1.9em;
  font-weight: 600;
  margin-top: -5px;
}
.small-graph-box .progress {
  background: rgba(0, 0, 0, 0.2);
  height: 5px;
  margin-bottom: 5px;
  margin-top: 10px;
}
.small-graph-box .progress .progress-bar {
  background-color: #fff;
}
.small-graph-box .subinfo {
  display: block;
  font-size: 0.8em;
  padding-top: 5px;
}
.small-graph-box .subinfo i {
  font-size: 1.2em;
}


/* PACE PRELOADER */
.pace .pace-progress {
  background: #ff6600;
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  height: 2px;
  -webkit-transition: width 1s;
          transition: width 1s;
}

.pace-inactive {
  display: none;
}

.daterange-filter {
  background: none repeat scroll 0 0 #FFFFFF;
  border: 1px solid #CCCCCC;
  cursor: pointer;
  padding: 5px 10px;
}

.main-box-header .daterange-filter {
  margin-top: 7px;
  margin-bottom: 7px;
}

.filter-block {
  width: 100%;
  margin: 0 auto;
  margin-top: 10px;
  padding: 0 15px 0 15px;
}
@media only screen and (max-width: 440px) {
  .filter-block {
    float: none !important;
    clear: both;
  }
}
.filter-block .form-group {
  margin-right: 10px;
  position: relative;
}
@media only screen and (max-width: 440px) {
  .filter-block .form-group {
    float: none !important;
    margin-right: 0;
  }
}
.filter-block .form-group .form-control {
  height: 36px;
}
.filter-block .form-group .search-icon {
  position: absolute;
  color: #707070;
  right: 8px;
  top: 11px;
}
.filter-block .btn {
  margin-left: 5px;
}
@media only screen and (max-width: 440px) {
  .filter-block .btn {
    display: block;
    float: none !important;
    margin-bottom: 15px;
    margin-left: 0;
  }
}

@media only screen and (max-width: 440px) {
  #reportrange {
    clear: both;
    float: none !important;
    margin-bottom: 15px;
  }
}

.the-icons {
  font-size: 0.875em;
  line-height: 2;
}
.the-icons .glyphicon {
  width: 30px;
  text-align: center;
}

.tabs-wrapper .nav-tabs {
  margin-bottom: 15px;
}
.tabs-wrapper .tab-content {
  margin-bottom: 20px;
  padding: 0 10px;
}

.dropdown-example ul.dropdown-menu {
  display: block;
}

svg {
  width: 100% !important;
}

.top-page-ui {
  margin-top: -10px;
}


.theme-whbl #nav-col,
.theme-whbl #page-wrapper {
  background-color: #e8ebf2;
}
.theme-whbl #sidebar-nav .nav > li > a {
  color: #16191c;
  /* border-bottom: 1px solid #dce1e4; */
}
.theme-whbl #sidebar-nav .nav > .open > .submenu > li > .submenu,
.theme-whbl #sidebar-nav .nav > .active > .submenu > li > .submenu {
  background-color: #dcdfe6;
}
.theme-whbl .nav-pills > li.active > a,
.theme-whbl .nav-pills > li.active > a:hover,
.theme-whbl .nav-pills > li.active > a:focus,
.theme-whbl #sidebar-nav .nav-pills > li.active > a,
.theme-whbl #sidebar-nav .nav-pills > li.active > a:hover,
.theme-whbl #sidebar-nav .nav-pills > li.active > a:focus,
.theme-whbl .nav-small #nav-col #sidebar-nav .nav-pills > li.open > a,
.theme-whbl .nav-small #nav-col #sidebar-nav .nav-pills > li.active > a {
  background-color: #e8ebf2;
  border-color: #ff6600;
  border-bottom-color: #dce1e4;
  color: #16191c;
}
.theme-whbl #sidebar-nav .nav-pills > li.active > a > i {
  color: #2980b9;
}
.theme-whbl #sidebar-nav .nav > li > a:hover {
  background-color: #e8ebf2;
  border-color: #ff6600;
  border-bottom-color: #dce1e4;
  color: #16191c;
}
.theme-whbl #header-navbar .nav > li > a {
  color: #fff;
}
.theme-whbl #header-navbar .nav > li > a:hover,
.theme-whbl #header-navbar .nav > li > a:focus,
.theme-whbl #header-navbar .nav .open > a,
.theme-whbl #header-navbar .nav .open > a:hover,
.theme-whbl #header-navbar .nav .open > a:focus {
  background-color: #ff6600;
}
.theme-whbl #sidebar-nav .nav li .submenu {
  background-color: #e8ebf2;
}
.theme-whbl #sidebar-nav .nav li .submenu > li > a {
  color: #16191c;
}
.theme-whbl #sidebar-nav .nav > .open > .submenu > .open > a,
.theme-whbl #sidebar-nav .nav > .active > .submenu > .open > a,
.theme-whbl #sidebar-nav .nav > .active > .submenu > .active > a {
  border-bottom-color: transparent;
  box-shadow: 0 -1px 0 transparent inset;
}
.theme-whbl #sidebar-nav .nav > .open > .submenu > .open > a {
  border-bottom-color: #dcdfe6;
  box-shadow: 0 -1px 0 #dcdfe6 inset;
}
.theme-whbl #sidebar-nav .nav li.open > a.dropdown-toggle > .drop-icon,
.theme-whbl #sidebar-nav .nav li.active > a.dropdown-toggle > .drop-icon {
  color: #16191c;
}
.theme-whbl #sidebar-nav .nav li .submenu > li > a:hover,
.theme-whbl #sidebar-nav .nav li .submenu > li > a.active,
.theme-whbl #sidebar-nav .nav li .submenu > li.active > a {
  background-color: #dbdee5;
}
.theme-whbl .navbar > .container .navbar-brand {
  color: #fff;
}
.theme-whbl .navbar-toggle {
  color: #fff;
}
.theme-whbl .graph-box {
  background-color: #ff6600 !important;
}
.theme-whbl #content-wrapper {
  background-color: #f1f3f7;
  border-left: 2px solid #dce1e4;
}
.theme-whbl #user-left-box {
  background-color: #fff;
  border-bottom: 1px solid #dce1e4;
  text-align: center;
}
.theme-whbl #user-left-box .user-box {
  color: #16191c;
}
.theme-whbl h1 {
  color: #ff6600;
}
.theme-whbl-v3 h1 {
  color: #696969;
}
.theme-whbl #header-navbar .nav > li > a:hover,
.theme-whbl #header-navbar .nav > li > a:focus,
.theme-whbl #header-navbar .nav .open > a,
.theme-whbl #header-navbar .nav .open > a:hover,
.theme-whbl #header-navbar .nav .open > a:focus,
.theme-whbl .navbar-toggle:hover,
.theme-whbl .navbar-toggle:focus,
.theme-whbl .mobile-search.active > .btn {
  background-color:#ff6600;
}
.theme-whbl a,
.theme-whbl .fc-state-default,
.theme-whbl .jvectormap-zoomin,
.theme-whbl .jvectormap-zoomout,
.theme-whbl #user-profile .profile-details ul > li > span {
  color: #ff6600;
}
.theme-whbl a:hover,
.theme-whbl a:focus,
.theme-whbl .widget-users li > .details > .name > a:hover,
.theme-whbl .widget-todo .actions > a:hover {
  color: #ff6600;
}
.theme-whbl .table a.table-link:hover {
  color: #2980b9;
}
.theme-whbl .pagination > li > a,
.theme-whbl .pagination > li > span,
.theme-whbl .pagination > li > a:hover,
.theme-whbl .pagination > li > span:hover,
.theme-whbl .pagination > li > a:focus,
.theme-whbl .pagination > li > span:focus,
.theme-whbl .pagination > li > a:active,
.theme-whbl .pagination > li > span:active {
  color: #ff6600;
}
.theme-whbl .pagination > .active > a,
.theme-whbl .pagination > .active > span,
.theme-whbl .pagination > .active > a:hover,
.theme-whbl .pagination > .active > span:hover,
.theme-whbl .pagination > .active > a:focus,
.theme-whbl .pagination > .active > span:focus {
  background-color: #ff6600;
  border-color: #ff6600;
  color: #fff;
}
.theme-whbl .notifications-list .item-footer {
  background-color: #272d33;
}
.theme-whbl .notifications-list .item-footer a:hover {
  background-color: #0f1114;
}
.theme-whbl .btn-primary,
.theme-whbl .btn-default,
.theme-whbl .btn-info,
.theme-whbl .btn-success,
.theme-whbl .btn-warning,
.theme-whbl .btn-danger,
.theme-whbl .btn-primary:hover,
.theme-whbl .btn-default:hover,
.theme-whbl .btn-info:hover,
.theme-whbl .btn-success:hover,
.theme-whbl .btn-warning:hover,
.theme-whbl .btn-danger:hover {
  color: #fff;
}
.theme-whbl .btn-primary {
  background-color: #ff6600;
  border-color: #2980b9;
}
.theme-whbl .btn-primary:hover,
.theme-whbl .btn-primary:focus,
.theme-whbl .btn-primary:active,
.theme-whbl .btn-primary.active,
.theme-whbl .open .dropdown-toggle.btn-primary {
  background-color: #2980b9;
  border-color: #216897;
}
.theme-whbl .btn-success {
  background-color: #ff6600;
  border-color: #2980b9;
}
.theme-whbl .btn-success:hover,
.theme-whbl .btn-success:focus,
.theme-whbl .btn-success:active,
.theme-whbl .btn-success.active,
.theme-whbl .open .dropdown-toggle.btn-success {
  background-color: #2980b9;
  border-color: #1c5c87;
}
.theme-whbl h1 {
  color: #ff6600;
}
.theme-whbl .widget-users li > .details > .time {
  color: #ff6600;
}
.theme-whbl blockquote,
.theme-whbl blockquote.pull-right {
  border-color: #ff6600;
}
.theme-whbl a.list-group-item.active,
.theme-whbl a.list-group-item.active:hover,
.theme-whbl a.list-group-item.active:focus {
  background-color: #ff6600;
  border-color: #ff6600;
}
.theme-whbl .nav .caret {
  border-bottom-color: #ff6600;
  border-top-color: #ff6600;
}
.theme-whbl .panel-default > .panel-heading,
.theme-whbl .notifications-list .item-footer {
  background-color: #ff6600;
}
.theme-whbl .notifications-list .item-footer a:hover {
  background-color: #2980b9;
}
.theme-whbl #invoice-companies .invoice-dates .invoice-number > span,
.theme-whbl .notifications-list .item a .time {
  color: #ff6600;
}
.theme-whbl .table thead > tr > th > a:hover span {
  color: #ff6600;
  border-color: #ff6600;
}
.theme-whbl .pace .pace-progress {
  background-color: #fff;
}

.rtl.theme-whbl #content-wrapper {
  border-left: 0;
  border-right: 2px solid #dce1e4;
}

@media (max-width: 991px) {
  .theme-whbl #logo.navbar-brand > img.normal-logo.logo-white {
    display: block;
  }
  .theme-whbl #logo.navbar-brand > img.normal-logo.logo-black {
    display: none;
  }
  .theme-whbl .navbar > .container .navbar-brand {
    background-color: #ff6600;
  }
}






.theme-whbl-v2 #sidebar-nav .nav-pills > li.active > a > div > i {
  color: #115785;
}

.theme-whbl-v2 #sidebar-nav .nav-pills > li.open > a > div > i {
  color: #115785;
}

.theme-whbl-v2 #nav-col,
.theme-whbl-v2 #page-wrapper {
  background-color: #e5e9f3;
}
.theme-whbl-v2 #sidebar-nav .nav > li > a {
  color: #16191c;
  /* border-bottom: 1px solid #dce1e4; */
}
.theme-whbl-v2 #sidebar-nav .nav > .open > .submenu > li > .submenu,
.theme-whbl-v2 #sidebar-nav .nav > .active > .submenu > li > .submenu {
  background-color: #d2daed;
}
.theme-whbl-v2 .nav-pills > li.active > a,
.theme-whbl-v2 .nav-pills > li.active > a:hover,
.theme-whbl-v2 .nav-pills > li.active > a:focus,
.theme-whbl-v2 #sidebar-nav .nav-pills > li.active > a,
.theme-whbl-v2 #sidebar-nav .nav-pills > li.active > a:hover,
.theme-whbl-v2 #sidebar-nav .nav-pills > li.active > a:focus,
.theme-whbl-v2 .nav-small #nav-col #sidebar-nav .nav-pills > li.open > a,
.theme-whbl-v2 .nav-small #nav-col #sidebar-nav .nav-pills > li.active > a {
  background-color: #d7e3f2;
  border-color: #07629f;
  border-bottom-color: #dce1e4;
  color: #16191c;
}
.theme-whbl-v2 #sidebar-nav .nav-pills > li.active > a > i {
  color: #115785;
}
.theme-whbl-v2 #sidebar-nav .nav > li > a:hover {
  background-color: #b5d0e6;
  border-color: #07629f;
  border-bottom-color: #dce1e4;
  color: #16191c;
}
.theme-whbl-v2 #header-navbar .nav > li > a {
  color: #fff;
}


.theme-whbl-v2 #header-navbar .nav > li > a:hover,
.theme-whbl-v2 #header-navbar .nav > li > a:focus,
.theme-whbl-v2 #header-navbar .nav .open > a,
.theme-whbl-v2 #header-navbar .nav .open > a:hover,
.theme-whbl-v2 #header-navbar .nav .open > a:focus {
  background-color: #07629f;
}
.theme-whbl-v2 #sidebar-nav .nav li .submenu {
  background-color: #d7e3f2;
}
.theme-whbl-v2 #sidebar-nav .nav li .submenu > li > a {
  color: #16191c;
}
.theme-whbl-v2 #sidebar-nav .nav > .open > .submenu > .open > a,
.theme-whbl-v2 #sidebar-nav .nav > .active > .submenu > .open > a,
.theme-whbl-v2 #sidebar-nav .nav > .active > .submenu > .active > a {
  border-bottom-color: transparent;
  box-shadow: 0 -1px 0 transparent inset;
}
.theme-whbl-v2 #sidebar-nav .nav > .open > .submenu > .open > a {
  border-bottom-color: #d2daed;
  box-shadow: 0 -1px 0 #d2daed inset;
}
.theme-whbl-v2 #sidebar-nav .nav li.open > a.dropdown-toggle > .drop-icon,
.theme-whbl-v2 #sidebar-nav .nav li.active > a.dropdown-toggle > .drop-icon {
  color: #16191c;
}
.theme-whbl-v2 #sidebar-nav .nav li .submenu > li > a:hover,
.theme-whbl-v2 #sidebar-nav .nav li .submenu > li > a.active,
.theme-whbl-v2 #sidebar-nav .nav li .submenu > li.active > a {
  background-color: #e4eff9;
}
.theme-whbl-v2 .navbar > .container .navbar-brand {
  color: #fff;
}
.theme-whbl-v2 .navbar-toggle {
  color: #fff;
}
.theme-whbl-v2 .graph-box {
  background-color: #07629f !important;
}
.theme-whbl-v2 #content-wrapper {
  background-color: #f1f3f7;
  border-left: 2px solid #dce1e4;
}
.theme-whbl-v2 #user-left-box {
  background-color: #fff;
  border-bottom: 1px solid #dce1e4;
  text-align: center;
}
.theme-whbl-v2 #user-left-box .user-box {
  color: #16191c;
}
.theme-whbl-v2 h1 {
  color: #07629f;
}
.theme-whbl-v2 #header-navbar .nav > li > a:hover,
.theme-whbl-v2 #header-navbar .nav > li > a:focus,
.theme-whbl-v2 #header-navbar .nav .open > a,
.theme-whbl-v2 #header-navbar .nav .open > a:hover,
.theme-whbl-v2 #header-navbar .nav .open > a:focus,
.theme-whbl-v2 .navbar-toggle:hover,
.theme-whbl-v2 .navbar-toggle:focus,
.theme-whbl-v2 .mobile-search.active > .btn {
  background-color:#0a5181;
}
.theme-whbl-v2 a,
.theme-whbl-v2 .fc-state-default,
.theme-whbl-v2 .jvectormap-zoomin,
.theme-whbl-v2 .jvectormap-zoomout,
.theme-whbl-v2 #user-profile .profile-details ul > li > span {
  color: #07629f;
}
.theme-whbl-v2 a:hover,
.theme-whbl-v2 a:focus,
.theme-whbl-v2 .widget-users li > .details > .name > a:hover,
.theme-whbl-v2 .widget-todo .actions > a:hover {
  color: #07629f;
}
.theme-whbl-v2 .table a.table-link:hover {
  color: #115785;
}
.theme-whbl-v2 .pagination > li > a,
.theme-whbl-v2 .pagination > li > span,
.theme-whbl-v2 .pagination > li > a:hover,
.theme-whbl-v2 .pagination > li > span:hover,
.theme-whbl-v2 .pagination > li > a:focus,
.theme-whbl-v2 .pagination > li > span:focus,
.theme-whbl-v2 .pagination > li > a:active,
.theme-whbl-v2 .pagination > li > span:active {
  color: #07629f;
}
.theme-whbl-v2 .pagination > .active > a,
.theme-whbl-v2 .pagination > .active > span,
.theme-whbl-v2 .pagination > .active > a:hover,
.theme-whbl-v2 .pagination > .active > span:hover,
.theme-whbl-v2 .pagination > .active > a:focus,
.theme-whbl-v2 .pagination > .active > span:focus {
  background-color: #07629f;
  border-color: #07629f;
  color: #fff;
}
.theme-whbl-v2 .notifications-list .item-footer {
  background-color: #272d33;
}
.theme-whbl-v2 .notifications-list .item-footer a:hover {
  background-color: #0f1114;
}
.theme-whbl-v2 .btn-primary,
.theme-whbl-v2 .btn-default,
.theme-whbl-v2 .btn-info,
.theme-whbl-v2 .btn-success,
.theme-whbl-v2 .btn-warning,
.theme-whbl-v2 .btn-danger,
.theme-whbl-v2 .btn-primary:hover,
.theme-whbl-v2 .btn-default:hover,
.theme-whbl-v2 .btn-info:hover,
.theme-whbl-v2 .btn-success:hover,
.theme-whbl-v2 .btn-warning:hover,
.theme-whbl-v2 .btn-danger:hover {
  color: #fff;
}
.theme-whbl-v2 .btn-primary {
  background-color: #07629f;
  border-color: #115785;
}
.theme-whbl-v2 .btn-primary:hover,
.theme-whbl-v2 .btn-primary:focus,
.theme-whbl-v2 .btn-primary:active,
.theme-whbl-v2 .btn-primary.active,
.theme-whbl-v2 .open .dropdown-toggle.btn-primary {
  background-color: #115785;
  border-color: #216897;
}
.theme-whbl-v2 .btn-success {
  background-color: #07629f;
  border-color: #115785;
}
.theme-whbl-v2 .btn-success:hover,
.theme-whbl-v2 .btn-success:focus,
.theme-whbl-v2 .btn-success:active,
.theme-whbl-v2 .btn-success.active,
.theme-whbl-v2 .open .dropdown-toggle.btn-success {
  background-color: #115785;
  border-color: #1c5c87;
}
.theme-whbl-v2 h1 {
  color: #07629f;
}
.theme-whbl-v2 .widget-users li > .details > .time {
  color: #07629f;
}
.theme-whbl-v2 blockquote,
.theme-whbl-v2 blockquote.pull-right {
  border-color: #07629f;
}
.theme-whbl-v2 a.list-group-item.active,
.theme-whbl-v2 a.list-group-item.active:hover,
.theme-whbl-v2 a.list-group-item.active:focus {
  background-color: #07629f;
  border-color: #07629f;
}
.theme-whbl-v2 .nav .caret {
  border-bottom-color: #07629f;
  border-top-color: #07629f;
}
.theme-whbl-v2 .panel-default > .panel-heading,
.theme-whbl-v2 .notifications-list .item-footer {
  background-color: #07629f;
}
.theme-whbl-v2 .notifications-list .item-footer a:hover {
  background-color: #115785;
}
.theme-whbl-v2 #invoice-companies .invoice-dates .invoice-number > span,
.theme-whbl-v2 .notifications-list .item a .time {
  color: #07629f;
}
.theme-whbl-v2 .table thead > tr > th > a:hover span {
  color: #07629f;
  border-color: #07629f;
}
.theme-whbl-v2 .pace .pace-progress {
  background-color: #fff;
}

.rtl.theme-whbl-v2 #content-wrapper {
  border-left: 0;
  border-right: 2px solid #dce1e4;
}



.theme-whbl-v3 #sidebar-nav .nav-pills > li.active > a > div > i {
  color: #f5f5f5;
}

.theme-whbl-v3 #nav-col,
.theme-whbl-v3 #page-wrapper {
  background-color: #e5e9f3;
}
.theme-whbl-v3 #sidebar-nav .nav > li > a {
  color: #f5f5f5;
  /* border-bottom: 1px solid #dce1e4; */
}
.theme-whbl-v3 #sidebar-nav .nav > .open > .submenu > li > .submenu,
.theme-whbl-v3 #sidebar-nav .nav > .active > .submenu > li > .submenu {
  background-color: #d2daed;
}
.theme-whbl-v3 .nav-pills > li.active > a,
.theme-whbl-v3 .nav-pills > li.active > a:hover,
.theme-whbl-v3 .nav-pills > li.active > a:focus,
.theme-whbl-v3 #sidebar-nav .nav-pills > li.active > a,
.theme-whbl-v3 #sidebar-nav .nav-pills > li.active > a:hover,
.theme-whbl-v3 #sidebar-nav .nav-pills > li.active > a:focus,
.theme-whbl-v3 .nav-small #nav-col #sidebar-nav .nav-pills > li.open > a,
.theme-whbl-v3 .nav-small #nav-col #sidebar-nav .nav-pills > li.active > a {
  background-color: #d7e3f2;
  border-color: #eb5e00;
  border-bottom-color: #dce1e4;
  color: #16191c;
}
.theme-whbl-v3 #sidebar-nav .nav-pills > li.active > a > i {
  color: #115785;
}
.theme-whbl-v3 #sidebar-nav .nav > li > a:hover {
  /* background-color: #b5d0e6;  */
  background-color: #d7e3f2;
  border-color: #07629f;
  border-bottom-color: #dce1e4;
  color: #16191c;
}
.theme-whbl-v3 #sidebar-nav .nav > li > a:hover .fa{
  color: #eb5e00;
}

.theme-whbl-v3 #header-navbar .nav > li > a {
  color: #fff;
}


.theme-whbl-v3 #header-navbar .nav > li > a:hover,
.theme-whbl-v3 #header-navbar .nav > li > a:focus,
.theme-whbl-v3 #header-navbar .nav .open > a,
.theme-whbl-v3 #header-navbar .nav .open > a:hover,
.theme-whbl-v3 #header-navbar .nav .open > a:focus {
  background-color: #07629f;
}
.theme-whbl-v3 #sidebar-nav .nav li .submenu {
  background-color: #d7e3f2;
}
.theme-whbl-v3 #sidebar-nav .nav li .submenu > li > a {
  color: #ffffff;
}
.theme-whbl-v3 #sidebar-nav .nav > .open > .submenu > .open > a,
.theme-whbl-v3 #sidebar-nav .nav > .active > .submenu > .open > a,
.theme-whbl-v3 #sidebar-nav .nav > .active > .submenu > .active > a {
  border-bottom-color: transparent;
  box-shadow: 0 -1px 0 transparent inset;
}
.theme-whbl-v3 #sidebar-nav .nav > .open > .submenu > .open > a {
  border-bottom-color: #d2daed;
  box-shadow: 0 -1px 0 #d2daed inset;
}
.theme-whbl-v3 #sidebar-nav .nav li.open > a.dropdown-toggle > .drop-icon,
.theme-whbl-v3 #sidebar-nav .nav li.active > a.dropdown-toggle > .drop-icon {
  color: #16191c;
}
.theme-whbl-v3 #sidebar-nav .nav li .submenu > li > a:hover,
.theme-whbl-v3 #sidebar-nav .nav li .submenu > li > a.active,
.theme-whbl-v3 #sidebar-nav .nav li .submenu > li.active > a {
  background-color: #051e37;
}
.theme-whbl-v3 .navbar > .container .navbar-brand {
  color: #fff;
}
.theme-whbl-v3 .navbar-toggle {
  color: #fff;
}
.theme-whbl-v3 .graph-box {
  background-color: #07629f !important;
}
.theme-whbl-v3 #content-wrapper {
  background-color: #f1f3f7;
  border-left: 2px solid #dce1e4;
}
.theme-whbl-v3 #user-left-box {
  background-color: #fff;
  border-bottom: 1px solid #dce1e4;
  text-align: center;
}
.theme-whbl-v3 #user-left-box .user-box {
  color: #16191c;
}
.theme-whbl-v3 h1 {
  color: #07629f;
}
.theme-whbl-v3 #header-navbar .nav > li > a:hover,
.theme-whbl-v3 #header-navbar .nav > li > a:focus,
.theme-whbl-v3 #header-navbar .nav .open > a,
.theme-whbl-v3 #header-navbar .nav .open > a:hover,
.theme-whbl-v3 #header-navbar .nav .open > a:focus,
.theme-whbl-v3 .navbar-toggle:hover,
.theme-whbl-v3 .navbar-toggle:focus,
.theme-whbl-v3 .mobile-search.active > .btn {
  background-color:#ff6600;
}
.theme-whbl-v3 a,
.theme-whbl-v3 .fc-state-default,
.theme-whbl-v3 .jvectormap-zoomin,
.theme-whbl-v3 .jvectormap-zoomout,
.theme-whbl-v3 #user-profile .profile-details ul > li > span {
  color: #004282;
}
.theme-whbl-v3 a:hover,
.theme-whbl-v3 a:focus,
.theme-whbl-v3 .widget-users li > .details > .name > a:hover,
.theme-whbl-v3 .widget-todo .actions > a:hover {
  color: #07629f;
}
.theme-whbl-v3 .table a.table-link:hover {
  color: #115785;
}
.theme-whbl-v3 .pagination > li > a,
.theme-whbl-v3 .pagination > li > span,
.theme-whbl-v3 .pagination > li > a:hover,
.theme-whbl-v3 .pagination > li > span:hover,
.theme-whbl-v3 .pagination > li > a:focus,
.theme-whbl-v3 .pagination > li > span:focus,
.theme-whbl-v3 .pagination > li > a:active,
.theme-whbl-v3 .pagination > li > span:active {
  color: #004282;
}
.theme-whbl-v3 .pagination > .active > a,
.theme-whbl-v3 .pagination > .active > span,
.theme-whbl-v3 .pagination > .active > a:hover,
.theme-whbl-v3 .pagination > .active > span:hover,
.theme-whbl-v3 .pagination > .active > a:focus,
.theme-whbl-v3 .pagination > .active > span:focus {
  background-color: #0052A1;
  border-color: #07629f;
  color: #fff;
}
.theme-whbl-v3 .notifications-list .item-footer {
  background-color: #272d33;
}
.theme-whbl-v3 .notifications-list .item-footer a:hover {
  background-color: #0f1114;
}
.theme-whbl-v3 .btn-primary,
.theme-whbl-v3 .btn-default,
.theme-whbl-v3 .btn-info,
.theme-whbl-v3 .btn-success,
.theme-whbl-v3 .btn-warning,
.theme-whbl-v3 .btn-danger,
.theme-whbl-v3 .btn-primary:hover,
.theme-whbl-v3 .btn-default:hover,
.theme-whbl-v3 .btn-info:hover,
.theme-whbl-v3 .btn-success:hover,
.theme-whbl-v3 .btn-warning:hover,
.theme-whbl-v3 .btn-danger:hover {
  color: #fff;
}
.theme-whbl-v3 .btn-primary {
  background-color: #0052A1;
  border-color: #1c5c87;
  color: #f5f5f5;
}
.theme-whbl-v3 .btn-primary:hover,
.theme-whbl-v3 .btn-primary:focus,
.theme-whbl-v3 .btn-primary:active,
.theme-whbl-v3 .btn-primary.active,
.theme-whbl-v3 .open .dropdown-toggle.btn-primary {
  background-color: #004282;
  border-color: #1c5c87;
  color: #f5f5f5;
}
.theme-whbl-v3 .btn-success {
  background-color: #0052A1;
  border-color: #004282;
}
.theme-whbl-v3 .btn-success:hover,
.theme-whbl-v3 .btn-success:focus,
.theme-whbl-v3 .btn-success:active,
.theme-whbl-v3 .btn-success.active,
.theme-whbl-v3 .open .dropdown-toggle.btn-success {
  background-color: #004282;
  border-color: #1c5c87;
  color: #f5f5f5;
}
.theme-whbl-v3 h1 {
  color: #07629f;
}
.theme-whbl-v3 .widget-users li > .details > .time {
  color: #07629f;
}
.theme-whbl-v3 blockquote,
.theme-whbl-v3 blockquote.pull-right {
  border-color: #07629f;
}
.theme-whbl-v3 a.list-group-item.active,
.theme-whbl-v3 a.list-group-item.active:hover,
.theme-whbl-v3 a.list-group-item.active:focus {
  background-color: #07629f;
  border-color: #07629f;
}
.theme-whbl-v3 .nav .caret {
  border-bottom-color: #07629f;
  border-top-color: #07629f;
}
.theme-whbl-v3 .panel-default > .panel-heading,
.theme-whbl-v3 .notifications-list .item-footer {
  background-color: #07629f;
}
.theme-whbl-v3 .notifications-list .item-footer a:hover {
  background-color: #115785;
}
.theme-whbl-v3 #invoice-companies .invoice-dates .invoice-number > span,
.theme-whbl-v3 .notifications-list .item a .time {
  color: #07629f;
}
.theme-whbl-v3 .table thead > tr > th > a:hover span {
  color: #07629f;
  border-color: #07629f;
}
.theme-whbl-v3 .pace .pace-progress {
  background-color: #fff;
}

.rtl.theme-whbl-v3 #content-wrapper {
  border-left: 0;
  border-right: 2px solid #dce1e4;
}

.theme-whbl-v3 #sidebar-nav .nav li .submenu {
  background: #0f3459;
  padding: 5px 0;
  margin: 0;
  list-style: none;
}

.theme-whbl-v3 #sidebar-nav .nav > .open > .submenu > .open > a {
  box-shadow: none;
}

.theme-whbl-v3 label{
  color: #003366 !important;
}
.theme-whbl-v3 #sidebar-nav .nav li .submenu > li > a {
  padding-left: 36px;
}

.button-table-cell {
  background-color: #004282 !important;
}

.theme-whbl-v3 .alert-info {
    background-color: #0052A1;
    border-color: #0C86FB;
    color: #f5f5f5;
}
.theme-whbl-v3 .alert-info a{
    color: #f5f5f5;
}


@media (max-width: 991px) {
  .theme-whbl-v2 #logo.navbar-brand > img.normal-logo.logo-white {
    display: block;
  }
  .theme-whbl-v2 #logo.navbar-brand > img.normal-logo.logo-black {
    display: none;
  }
  .theme-whbl-v2 .navbar > .container .navbar-brand {
    background-color: #07629f;
  }
}
